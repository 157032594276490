.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.align-center{
  text-align: center;
}
.tmp-glink{
  margin: 5px auto!important;
  border: solid 2px #e29f15;
  padding: 10px 20px;
  border-radius: 3px;
  color: #e29f15;
  text-decoration: none;
  font-weight: bold;
}
.tmp-glink:hover{
  background-color: #e29f15;
  color: #fff;
}
.word-break{
  word-break: break-all;
}
.MuiTableCell-root a.btn.btn-xs.btn-secondary {
  padding: 7px 10px;
  border: solid 1px #ececec;
  border-radius: 5px;
  background: #ff5722;
  color: #fff;
  text-decoration: none;
}
.MuiTableCell-root a.btn.btn-xs.btn-secondary:hover {
  background: #607d8b;
}

#yousign-iframe {
  width: 100%;
  height: calc(100vh - 74px);
  border: 0;
}
